import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";



const EmployeeRegisterForm = () => {
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        image: null,
        surname: '',
        name: '',
        street: '',
        hourseNumber: '',
        city: '',
        zipCode: '',
        gender: 'divers',
        dob: '',
        placeOfBirth: '',
        national: '',
        maritalStatus: 'Ledig',
        haveChildren: 'Nein',
        children: [],
        residencePermit: 'Aufenthaltserlaubnis',
        pensionInsurance: '',
        incomeTaxClass: '1',
        anotherWork: false,
        anotherWorkDetails: [],
        healthInsurance: '',
        taxNumber: '',
        email: '',
        phoneNumber: '',
        bankAccountDetail: '',
        bankAddress: '',
        ok1: false,
        ok2: false,
    });

    const handleChange = (e, index = null) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'file') {
            if (files && files[0]) {
                const file = files[0];
                if (file.type.startsWith('image/')) {
                    setFormData(prevState => ({
                        ...prevState,
                        [name]: file
                    }));
                } else {
                    alert('Please upload a valid image file.');
                }
            }
        } else if (name.startsWith('children[') && index !== null) {
            const fieldName = name.split('.')[1];
            setFormData(prevState => {
                const updatedChildren = [...prevState.children];
                updatedChildren[index] = {
                    ...updatedChildren[index],
                    [fieldName]: value
                };
                return {
                    ...prevState,
                    children: updatedChildren
                };
            });
        } else if (name.startsWith('anotherWorkDetails[') && index !== null) {
            const fieldName = name.split('.')[1];
            setFormData(prevState => {
                const updatedAnotherWorkDetails = [...prevState.anotherWorkDetails];
                updatedAnotherWorkDetails[index] = {
                    ...updatedAnotherWorkDetails[index],
                    [fieldName]: value
                };
                return {
                    ...prevState,
                    anotherWorkDetails: updatedAnotherWorkDetails
                };
            });
        } else if (name === 'anotherWork') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const handleAddChild = () => {
        setFormData(prevState => ({
            ...prevState,
            children: [...prevState.children, { name: '', dob: '' }]
        }));
    };

    const handleAddAnotherWork = () => {
        setFormData(prevState => ({
            ...prevState,
            anotherWorkDetails: [...prevState.anotherWorkDetails, { salaryIs: '', name: '' }]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.ok1 === true && formData.ok2 === true) {
            try {
                const formDataToSend = new FormData();
                Object.keys(formData).forEach(key => {
                    if (Array.isArray(formData[key])) {
                        formData[key].forEach((item, index) => {
                            Object.keys(item).forEach(subKey => {
                                formDataToSend.append(`${key}[${index}].${subKey}`, item[subKey]);
                            });
                        });
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                const response = await axios.post(
                    `${baseUrl}/api/crm/create-employee-request`,
                    formDataToSend,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                alert("Successfully!");
                navigate('/');
            } catch (error) {
                console.log(error);
                alert(error.response?.data?.message || 'An error occurred.');
            }
        } else {
            alert("Bitte bestätige deine Datenschutzerklärung!");
        }
    };

    return (
        <div className="container mt-5 mb-5">
            <h1 className="text-center">Register employee</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formImage">
                    <Form.Label>Dein Foto<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="file" name="image" onChange={handleChange} accept="image/*"/>
                </Form.Group>

                <Form.Group controlId="formSurname">
                    <Form.Label>Nachname<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="surname" value={formData.surname} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formName">
                    <Form.Label>Vorname<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required/>
                </Form.Group>

                <h2 className='text-center'>Anschrift</h2>

                <Form.Group controlId="formStreet">
                    <Form.Label>Straße<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="street" value={formData.street} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formHouseNumber">
                    <Form.Label>Hausnummer<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="hourseNumber" value={formData.hourseNumber} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formCity">
                    <Form.Label>Stadt<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formZipCode">
                    <Form.Label>Postleizahl<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formGender">
                    <Form.Label>Gender<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange}>
                        <option value="männlich">Männlich</option>
                        <option value="weiblich">Weiblich</option>
                        <option value="divers">Divers</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formDob">
                    <Form.Label>Geburtsdatum<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="date" name="dob" value={formData.dob} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formPlaceOfBirth">
                    <Form.Label>Geburtsort<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="placeOfBirth" value={formData.placeOfBirth} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formNational">
                    <Form.Label>Nationalität<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="national" value={formData.national} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formMaritalStatus">
                    <Form.Label>Familienstand<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control as="select" name="maritalStatus" value={formData.maritalStatus}
                                  onChange={handleChange} required>
                        <option value="Ledig">Ledig</option>
                        <option value="Verheiratet">Verheiratet</option>
                        <option value="Verwitwet">Verwitwet</option>
                        <option value="Eingetragene Lebenspartnerschaft">Eingetragene Lebenspartnerschaft</option>
                        <option value="Getrennt lebend">Getrennt lebend</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formHaveChildren">
                    <Form.Label>Haben Sie Kinder?<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control as="select" name="haveChildren" value={formData.haveChildren} onChange={handleChange}>
                        <option value="Ja">Ja</option>
                        <option value="Nein">Nein</option>
                    </Form.Control>
                </Form.Group>

                {formData.haveChildren === 'Ja' && (
                    <>
                        <h3>Children Details</h3>
                        {formData.children.map((child, index) => (
                            <div key={index} className="child-details">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId={`childName_${index}`}>
                                            <Form.Label>Name, Vorname</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`children[${index}].name`}
                                                value={child.name}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId={`childDob_${index}`}>
                                            <Form.Label>Geburtsdatum</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name={`children[${index}].dob`}
                                                value={child.dob}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                        ))}
                        <Button className="mt-2" onClick={handleAddChild}>Add Child</Button>
                    </>
                )}

                <Form.Group controlId="formResidencePermit">
                    <Form.Label>Aufenthaltserlaubnis<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control as="select" name="residencePermit" value={formData.residencePermit}
                                  onChange={handleChange}>
                        <option value="Aufenthaltserlaubnis">Aufenthaltserlaubnis</option>
                        <option value="Niederlassungserlaubnis">Niederlassungserlaubnis</option>
                        <option value="Daueraufenthaltsrecht">Daueraufenthaltsrecht</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formPensionInsurance">
                    <Form.Label>Rentenversicherung<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="pensionInsurance" value={formData.pensionInsurance}
                                  onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formIncomeTaxClass">
                    <Form.Label>Steuerklasse<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control as="select" name="incomeTaxClass" value={formData.incomeTaxClass}
                                  onChange={handleChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formAnotherWork">
                    <Form.Check
                        type="checkbox"
                        name="anotherWork"
                        label="Another Work"
                        checked={formData.anotherWork}
                        onChange={handleChange}
                    />
                </Form.Group>

                {formData.anotherWork && (
                    <>
                        <h3>Another Work Details</h3>
                        {formData.anotherWorkDetails.map((detail, index) => (
                            <div key={index} className="another-work-detail">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId={`anotherWorkSalaryIs_${index}`}>
                                            <Form.Label>Dort erhalten Sie ein monatliches Entgelt von
                                                (Euro):</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`anotherWorkDetails[${index}].salaryIs`}
                                                value={detail.salaryIs}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId={`anotherWorkName_${index}`}>
                                            <Form.Label>Der zweite Arbeitgeber lautet:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={`anotherWorkDetails[${index}].name`}
                                                value={detail.name}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Button className="mt-2" onClick={handleAddAnotherWork}>Add Another Work</Button>
                    </>
                )}

                <Form.Group controlId="formHealthInsurance">
                    <Form.Label>Health Insurance<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="healthInsurance" value={formData.healthInsurance}
                                  onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formTaxNumber">
                    <Form.Label>Tax Number<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="taxNumber" value={formData.taxNumber} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formEmail">
                    <Form.Label>Email<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Phone Number<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formBankAccountDetail">
                    <Form.Label>Bank Account Detail<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="bankAccountDetail" value={formData.bankAccountDetail}
                                  onChange={handleChange} required/>
                </Form.Group>

                <Form.Group controlId="formBankAddress">
                    <Form.Label>Bank Address<span style={{color: 'red'}}>*</span></Form.Label>
                    <Form.Control type="text" name="bankAddress" value={formData.bankAddress} onChange={handleChange}
                                  required/>
                </Form.Group>

                <Form.Group controlId="formOk1">
                    <Form.Check
                        type="checkbox"
                        name="ok1"
                        label="Mitführungs- und Vorlagepflicht von Ausweispapieren (Gemäß § 2a des Schwarzarbeitsbekämpfungsgesetzes) Bei der Erbringung von Dienst- oder Werkleistungen sind die in den oben genannten Wirtschaftsbereichen oder Wirtschaftszweigen tätigen Personen verpflichtet, ihren Personalausweis, Pass, Passersatz oder Ausweisersatz mitzuführen und den Behörden der Zollverwaltung auf Verlangen vorzulegen."
                        checked={formData.ok1}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formOk2">
                    <Form.Check
                        type="checkbox"
                        name="ok2"
                        label="Ich versichere, dass die vorstehenden Angaben der Wahrheit entsprechen. Ich verpflichte mich, meinem Arbeitgeber alle Änderungen, insbesondere in Bezug auf weitere Beschäftigungen (in Bezug auf Art, Dauer und Entgelt) unverzüglich mitzuteilen. Über die gesetzlich notwendige Mitführung und Vorlagepflicht meiner Ausweispapiere während der Beschäftigung bin ich hingewiesen worden."
                        checked={formData.ok2}
                        onChange={handleChange}
                    />
                </Form.Group>

                <div className="row justify-content-center mt-2">
                    <div className="col-auto">
                        <Link to="/" className="btn btn-secondary mx-2">
                            Back to Login
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Button type="submit" variant="primary" disabled={!formData.ok1 ||!formData.ok2} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>

            </Form>
        </div>
);
};

export default EmployeeRegisterForm;
