import React, { useState } from "react";
import axios from "axios";
import "./ChangePassword.css"; // Thêm CSS tùy chỉnh
import Navigation from './../../components/Navigation/Navigation';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_API_URL;


    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!currentPassword || !newPassword || !confirmPassword) {
            setError("All fields are required.");
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("New password and confirm password do not match.");
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/auth/password/change-pass`, {
                currentPassword,
                newPassword,
                confirmPassword
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
            );

            if (response.status === 200) {
                setMessage("Password has been changed successfully.");
                setError("");
            } else {
                setError("Failed to change password. Please try again.");
                setMessage("");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            setMessage("");
        }
        setIsLoading(false);
    };

    return (
        <>
            <Navigation />
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h2 className="text-center mb-4">Change Password</h2>
                                <form onSubmit={handleChangePassword}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="currentPassword">Current Password:</label>
                                        <input
                                            type="password"
                                            id="currentPassword"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="newPassword">New Password:</label>
                                        <input
                                            type="password"
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="confirmPassword">Confirm New Password:</label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="btn btn-primary w-100"
                                    >
                                        {isLoading ? "Changing..." : "Change Password"}
                                    </button>
                                </form>
                                {message && <p className="text-success text-center mt-3">{message}</p>}
                                {error && <p className="text-danger text-center mt-3">{error}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ChangePassword;
