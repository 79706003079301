import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ResetPassword.css";

const ResetPassword = () => {
    const [isTokenValid, setIsTokenValid] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_API_URL;

    // Lấy token từ URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.post(`${baseUrl}/api/auth/manage-password/confirm-token-employee`, { token });
                if (response.status == 200) {
                    setIsTokenValid(true);
                } else {
                    setIsTokenValid(false);
                }
            } catch (error) {
                setIsTokenValid(false);
            }
        };

        verifyToken();
    }, [token]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${baseUrl}/api/auth/manage-password/change-pass-fogot-employee`, {
                token,
                newPassword,
                confirmPassword
            });

            if (response.status === 200) {
                setSuccessMessage("Password has been reset successfully.");
                setTimeout(() => {
                    window.location.href = "/";
                }, 2000);
            } else {
                setErrorMessage("Failed to reset password.");
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div className="reset-password-container">
            {isTokenValid === null ? (
                <p>Loading...</p>
            ) : isTokenValid ? (
                <form onSubmit={handlePasswordReset}>
                    <h2>Reset Password</h2>
                    <div className="form-group">
                        <label>New Password:</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    <button type="submit">Submit</button>
                </form>
            ) : (
                <p className="error-message">The token has expired. Please request a new one.</p>
            )}
            {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
    );
};

export default ResetPassword;
