import React, { useState } from "react";
import axios from "axios";
import "./ForgotPassword.css";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_API_URL;

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/auth/manage-password/reset-employee`, { email });
            console.log(response)

            if (response.status == 200) {
                setMessage("A reset link has been sent to your email.");
                setError("");
            } else {
                setError("Failed to send reset link. Please try again.");
                setMessage("");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            setMessage("");
        }
        setIsLoading(false);
    };

    return (
        <div className="forgot-password-container mt-4">
            <h2>Forgot Password</h2>
            <form onSubmit={handleForgotPassword}>
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? "Sending..." : "Send Reset Link"}
                </button>
            </form>
            <a className="btn btn-info w-100 mt-4" href="./">Login</a>

            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default ForgotPassword;
